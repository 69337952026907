import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'
import ExpandCollapse from 'react-expand-collapse'

const converter = new showdown.Converter()

const ReadMoreText = ({ before, content, className, expanded }) => (
  <div className={className}>
    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(before) }} />

    <ExpandCollapse
      previewHeight="0px"
      expandText="Learn more"
      ellipsisText=""
      collapseText=""
    >
      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
    </ExpandCollapse>
  </div>
)

ReadMoreText.propTypes = {
  before: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  // expanded: false,
}

export default ReadMoreText
