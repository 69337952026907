import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'

const converter = new showdown.Converter()

const Sidebar = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
)

Sidebar.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

export default Sidebar
