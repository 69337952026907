import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/content'
import ReadMoreText from '../components/readmore'
import Sidebar from '../components/sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export const LandingTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  read_before,
  read_more,
  sidebar,
  previous,
  section,
  current,
  total,
  next,
  helmet,
}) => {
  const LandingContent = contentComponent || Content

  return (
    <article className={'section-' + section + ' landing'}>
      <div className="landing-head">
        <h1>
          {title}
          {subtitle ? <span className="subtitle">{subtitle}</span> : null}
        </h1>
        {sidebar ? <Sidebar content={sidebar} className="sidebar" /> : null}
        {read_more ? (
          <ReadMoreText
            before={read_before}
            content={read_more}
            className="read-more"
          />
        ) : null}
      </div>
      <div className="landing-body">
        <LandingContent content={content} className="landing__content" />
        <div className="section-nav">
          <div className="section-nav__previous">
            {previous ? (
              <Link to={previous}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className="sr-only"> Previous</span>
              </Link>
            ) : null}
          </div>

          {/* <div className="section-nav__count">
            {current} of {total}
          </div> */}

          <div className="section-nav__next">
            {next ? (
              <Link to={next}>
                <span className="sr-only">Next </span>
                <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </article>
  )
}

LandingTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sidebar: PropTypes.string,
  read_more: PropTypes.string,
  previous: PropTypes.string,
  section: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  next: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const Landing = ({ data }) => {
  const { markdownRemark: landing } = data

  return (
    <Layout>
      <LandingTemplate
        content={landing.html}
        subtitle={landing.frontmatter.subtitle}
        sidebar={landing.frontmatter.sidebar}
        read_before={landing.frontmatter.read_before}
        read_more={landing.frontmatter.read_more}
        previous={landing.frontmatter.previous}
        section={landing.frontmatter.section}
        current={landing.frontmatter.current}
        total={landing.frontmatter.total}
        next={landing.frontmatter.next}
        contentComponent={HTMLContent}
        helmet={<Helmet title={`${landing.frontmatter.title}`} />}
        title={landing.frontmatter.title}
      />
    </Layout>
  )
}

Landing.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Landing

export const landingQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        sidebar
        read_before
        read_more
        previous
        section
        current
        total
        next
      }
    }
  }
`
